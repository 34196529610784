<template>
  <div>
    <div>
      skus: {{ value }}
    </div>
    <div
      v-if="hasVariant"
      class="py-50"
    >
      <hr class="mb-0 mt-2">
    </div>

    <!-- sku table -->
    <div
      v-if="hasVariant"
      class="mt-1 px-50 border rounded"
    >
      <table class="w-100">
        <thead>
          <tr>
            <th
              width="2%"
              class="px-25 py-1"
            />
            <th
              v-if="variantLabel"
              width="7%"
              class="px-25 py-1"
            >
              {{ variantLabel }}
            </th>
            <th
              v-else
              width="7%"
              class="px-25 py-1"
            />
            <th
              v-if="subVariantLabel && subVariants.length"
              width="7%"
              class="px-25 py-1"
            >
              {{ subVariantLabel }}
            </th>
            <th
              width="10%"
              class="px-25 py-1"
            >
              Harga
            </th>
            <th
              width="10%"
              class="px-25 py-1"
            >
              Harga coret
            </th>
            <th
              width="5%"
              class="px-25 py-1"
            >
              Stok
            </th>
            <th
              width="7%"
              class="px-25 py-1"
            >
              SKU
            </th>
            <th
              width="8%"
              class="px-25 py-1"
            >
              Berat
            </th>
            <th
              width="6%"
              class="px-25 py-1"
            />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in filteredSkus"
            :key="`sku-${index}`"
            :style="{ display: value._is_deleted ? 'none' : undefined }"
          >
            <td class="px-25 py-50">
              <b-img
                v-if="item.variant.image_url"
                :src="item.variant.image_url | thumbnail(48)"
                rounded
                class="border"
                width="48px"
                height="48px"
              />
              <b-img
                v-else
                src="https://placehold.co/48x48?text=No+image"
                rounded
                class="border"
                width="48px"
                height="48px"
              />
            </td>
            <td class="px-25 py-50">
              {{ item.variant.name }} ({{ item.id || 'new' }})
            </td>
            <td
              v-if="variantLabel && subVariants.length"
              class="px-25 py-50"
            >
              {{ item.sub_variant.name }}
            </td>
            <td class="px-25 py-50">
              <b-input-group>
                <b-input-group-prepend is-text>
                  Rp
                </b-input-group-prepend>
                <b-form-input
                  :value="item.price"
                  type="number"
                  placeholder="0"
                  required
                  @input="inputValue => input(index, 'price', !inputValue ? null : parseFloat(inputValue))"
                />
              </b-input-group>
            </td>
            <td class="px-25 py-50">
              <b-input-group>
                <b-input-group-prepend is-text>
                  Rp
                </b-input-group-prepend>
                <b-form-input
                  :value="item.compare_price"
                  type="number"
                  placeholder="0"
                  @input="inputValue => input(index, 'compare_price', !inputValue ? null : parseFloat(inputValue))"
                />
              </b-input-group>
            </td>
            <td class="px-25 py-50">
              <b-form-input
                :value="item.stock"
                type="number"
                placeholder="0"
                required
                @input="inputValue => input(index, 'stock', !inputValue ? null : parseFloat(inputValue))"
              />
            </td>
            <td class="px-25 py-50">
              <b-form-input
                :value="item.sku_number"
                placeholder="SKU"
                @input="inputValue => input(index, 'sku_number', inputValue)"
              />
            </td>
            <td class="px-25 py-50">
              <b-input-group>
                <b-form-input
                  :value="item.weight"
                  type="number"
                  placeholder="0"
                  required
                  @input="inputValue => input(index, 'weight', !inputValue ? null : parseFloat(inputValue))"
                />
                <b-input-group-append is-text>
                  g
                </b-input-group-append>
              </b-input-group>
            </td>
            <td
              v-if="index === 0"
              class="px-25 py-50"
            >
              Utama
              <feather-icon
                v-b-tooltip.hover
                title="Varian produk ini diterapkan sebagai pilihan utama"
                icon="AlertCircleIcon"
                class="mr-50"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {
  BImg,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
} from 'bootstrap-vue'
// import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    BImg,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    // ValidationProvider,
  },
  props: {
    value: {
      type: Array,
      required: null,
    },
    variant: {
      type: Object,
      default: () => {},
    },
    subVariant: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      variantInput: '',
      subVariantInput: '',
      hasVariant: false,
      hasSubVariant: false,
      // variantLabel: null,
      // subVariantLabel: null,
      // variants: [],
      // subVariants: [],
      // skus: [],
      options: ['ukuran', 'warna', 'laminasi'],
    }
  },
  computed: {
    variants() {
      if (!this.variant) {
        return []
      }

      return this.variant.options
    },
    variantLabel() {
      if (!this.variant) {
        return null
      }

      return this.variant.name
    },
    subVariants() {
      if (!this.subVariant) {
        return []
      }

      return this.subVariant.options
    },
    subVariantLabel() {
      if (!this.subVariant) {
        return null
      }

      return this.subVariant.name
    },
    filteredSkus() {
      return this.value.filter(item => !item._is_deleted)
    },
  },
  watch: {
    variants() {
      // console.log('variants', value, oldValue)
    },
    subVariantLabel(value, oldValue) {
      // The admin just select the sub-variant-label
      // reset the skus
      if (!oldValue && value) {
        this.skus = []
      }
    },
    value(value, oldValue) {
      // console.log('sku berubah', value, oldValue)
      // ignore if not first value bind. value bind is only on update mode
      if (oldValue && oldValue.length) {
        return
      }

      if (this.variants.length) {
        this.hasVariant = true
      }
    },
  },
  methods: {
    input(index, field, value) {
      const newValue = value || null

      // do not flag as _is_updated for newly created sku
      // because they should be treated as created,
      // and they're still going to the API anyway
      if (this.value[index]._is_added) {
        this.value[index][field] = newValue
        this.input()
        return
      }

      // if there are no changes from previous value(not initial), ignore
      if (this.value[index][field] === newValue) {
        return
      }

      // if the _initial object is not defined
      if (!this.value[index]._initial) {
        // then store the current field's initial value while creating the _initial object
        this.value[index]._initial = { [field]: this.value[index][field] }
        this.value[index]._is_updated = true // means _initial object is defined and not empty object
      } else if
      // if the _initial object is defined but the current field's initial value wasn't there
      (this.value[index]._initial && this.value[index]._initial[field] === undefined) {
        // then put the current field's initial value there
        this.value[index]._initial[field] = this.value[index][field]
      } else if
      // if the new value is the same as the initial value
      (this.value[index]._initial && this.value[index]._initial[field] === newValue) {
        // remove the field from the initial array
        // because initial object only consist of modified fields
        delete this.value[index]._initial[field]
      }

      // then just remove the _is_updated key and _initial object
      // because it is not needed anymore if there is no changes
      if (Object.keys(this.value[index]._initial).length === 0) {
        delete this.value[index]._initial
        delete this.value[index]._is_updated
      }

      // replace current field's value using the new value
      this.value[index][field] = newValue
      this.$emit('change')
    },
  },
}
</script>

<style scoped>

</style>
