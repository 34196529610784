<template>
  <b-overlay
    opacity="0.5"
    :show="loadingDetail || loadingSubmit"
  >
    <div
      v-if="showFormData"
      class="mb-2 py-1 rounded font-monospace s-12 border"
    >
      <div
        v-for="(field, fieldKey) in defaultFields"
        :key="fieldKey"
        :class="{ 'mt-1' : fieldKey > 0 }"
      >
        <div
          v-for="(value, key) in field"
          :key="key"
          class="mb-25"
        >
          <b-row>
            <b-col
              cols="2"
              class="text-right opacity-75"
            >
              {{ key }} :
            </b-col>
            <b-col :class="{ 'font-weight-bold text-danger': key === 'key' }">
              <span
                v-if="typeof value === 'string'"
                class="text-success"
              >'{{ value }}'</span>
              <span
                v-else-if="typeof value === 'boolean'"
                class="text-warning"
              >{{ value }}</span>
              <span
                v-else-if="typeof value === 'number'"
                class="text-danger"
              >{{ value }}</span>
              <span v-else>{{ value }}</span>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <!-- Form -->
    <validation-observer ref="formValidation">
      <b-form
        ref="form"
        class="mt-50"
        @submit.prevent=""
      >
        <div
          v-for="(item, index) in defaultFields"
          :key="`field-${item.key}-${index}`"
        >
          <!-- Section -->
          <div
            v-if="item.type === 'section' && (!item.visibility || item.visibility.callback(defaultFields))"
            class="mb-3"
          >
            <hr
              v-if="index > 0"
              class="mb-2"
            >
            <h3>{{ item.title }}</h3>
            <p>{{ item.description }}</p>
          </div>

          <VariantEditor
            v-else-if="item.type === 'variant'"
            v-model="item.value"
            :sub-variant="_.find(defaultFields, field => field.key === item.subVariant).value"
            :skus="_.find(defaultFields, field => field.key === item.skus).value"
            class="mb-3"
            @skus="(value) => changeSkus(value, item)"
            @input="input"
          />

          <SubVariantEditor
            v-else-if="item.type === 'sub-variant'"
            v-model="item.value"
            :variant="_.find(defaultFields, field => field.key === item.variant).value"
            :skus="_.find(defaultFields, field => field.key === item.skus).value"
            class="mb-3"
            @skus="(value) => changeSkus(value, item)"
            @input="input"
          />

          <SkuEditor
            v-else-if="item.type === 'sku'"
            v-model="item.value"
            :variant="_.find(defaultFields, field => field.key === item.variant).value"
            :sub-variant="_.find(defaultFields, field => field.key === item.subVariant).value"
            class="mb-3"
            @change="input"
          />

          <!-- Fields -->
          <b-row
            v-else-if="!item.visibility || item.visibility.callback(defaultFields)"
            class="mb-2"
          >
            <!-- Label and note -->
            <b-col
              md="4"
              :xl="block ? 4 : 3"
            >
              <FormLabel
                :label="item.label || _.startCase(item.key)"
                :required="item.required"
              />
              <!-- Notes -->
              <div
                v-if="item.notes && Array.isArray(item.notes) && item.notes.length"
                class="mt-75"
              >
                <p
                  v-for="(note, noteIndex) in item.notes"
                  :key="`field-${item.key}-${index}-note-${noteIndex}`"
                  class="s-12 mb-50"
                  v-html="note"
                />
              </div>
              <div
                v-else-if="item.notes"
                class="mt-75"
              >
                <p
                  class="s-12 mb-50"
                  v-html="item.notes"
                />
              </div>
            </b-col>

            <!-- Fields -->
            <b-col
              md="8"
              :xl="block ? 8 : 6"
            >
              <validation-provider
                #default="{ errors }"
                :name="item.label || _.startCase(item.key)"
                :rules="getRules(item)"
              >
                <!-- Email -->
                <b-form-input
                  v-if="item.type === 'email'"
                  v-model="item.value"
                  type="email"
                  :state="errors.length > 0 ? false:null"
                  @input="input"
                />
                <!-- Password -->
                <b-form-input
                  v-else-if="item.type === 'password'"
                  v-model="item.value"
                  autocomplete="new-password"
                  type="password"
                  :state="errors.length > 0 ? false:null"
                  @input="input"
                />
                <!-- Textarea -->
                <b-form-textarea
                  v-else-if="item.type === 'textarea'"
                  v-model="item.value"
                  :rows="item.rows || 3"
                  :state="errors.length > 0 ? false:null"
                  @input="input"
                />
                <!-- Switch -->
                <b-form-checkbox
                  v-else-if="item.type === 'switch'"
                  v-model="item.value"
                  switch
                  inline
                  @input="input"
                >
                  {{ item.text }}
                </b-form-checkbox>
                <!-- Single and multiple select -->
                <div v-else-if="item.type === 'select' || item.type === 'multiple'">
                  <!-- For 'required' rule validation workaround -->
                  <!-- Because the item.selected is always null for 'multiple' type -->
                  <input
                    v-if="item.type === 'multiple'"
                    :ref="`select-input-${item.key}`"
                    :value="item.value && item.value.length ? true : undefined"
                    type="text"
                    class="vs__multiple_select_input"
                  >
                  <v-select
                    v-if="item.actionSearch || item.options"
                    v-model="item.selected"
                    placeholder="Pilih"
                    :label="item.text || 'text'"
                    :filterable="!item.actionSearch"
                    :options="item.stateOptions ? _.get($store.state, item.stateOptions) : item.options"
                    @option:selected="option => inputSelect(item, option)"
                    @input="option => inputDeselect(item, option)"
                    @close="onBlurSelect(item)"
                    @search="(keyword, setLoading) => searchOptions(item, keyword, setLoading)"
                  >
                    <!-- option -->
                    <template v-slot:option="option">
                      {{ option[item.text || 'text'] }}
                    </template>
                    <!-- No options -->
                    <template #no-options="{ search }">
                      <span v-if="search">Tidak ada hasil untuk "{{ search }}".</span>
                      <span v-else-if="!item.actionSearch">Tidak ada pilihan</span>
                      <span v-else>Ketik untuk mencari...</span>
                    </template>
                  </v-select>
                  <b-input-group
                    v-else
                    class="input-group-merge"
                  >
                    <b-form-input
                      :state="errors.length > 0 ? false:null"
                      @keydown.enter.prevent="e => inputBasicMultiple(item, e)"
                    />

                    <b-input-group-append
                      v-if="!item.actionSearch && !item.options"
                      is-text
                    >
                      <small class="mr-25">Enter</small><feather-icon icon="CornerDownLeftIcon" />
                    </b-input-group-append>
                  </b-input-group>

                  <div
                    v-if="item.type === 'multiple' && item.value && item.value.length"
                    class="d-flex gap-1 flexwrap-wrap mt-50"
                  >
                    <b-badge
                      v-for="(selected, selectedIndex) in item.valueOptions"
                      :key="`field-${item.key}-${index}-selected-${selectedIndex}`"
                      class="whitespace-nowrap"
                      variant="primary"
                    >
                      {{ selected[item.text || 'text'] }}
                      <feather-icon
                        class="cursor-pointer"
                        icon="XIcon"
                        size="12"
                        @click="removeOption(item, selectedIndex)"
                      />
                    </b-badge>
                  </div>
                </div>
                <!-- Radio -->
                <b-form-radio-group
                  v-else-if="item.type === 'radio'"
                  v-model="item.value"
                  :options="item.options"
                  @change="input"
                />
                <!-- Slider -->
                <vue-slider
                  v-else-if="item.type === 'slider'"
                  v-model="item.value"
                  class="mb-2"
                  :min="item.min"
                  :max="item.max"
                  :marks="item.marks"
                  :interval="item.interval"
                  @change="input"
                />
                <!-- Datetime -->
                <b-input-group
                  v-else-if="item.type === 'datetime'"
                  class="input-group-merge position-relative"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="CalendarIcon" />
                  </b-input-group-prepend>
                  <flat-pickr
                    v-model="item.value"
                    placeholder="Select date"
                    class="form-control"
                    :config="flatPickrConfig"
                    @input="input"
                  />
                </b-input-group>
                <!-- Quill text editor -->
                <quill-editor
                  v-else-if="item.type === 'quill'"
                  v-model="item.value"
                  :options="quillOptions"
                />
                <!-- Upload image -->
                <image-field
                  v-else-if="item.type === 'image'"
                  v-model="item.value"
                  :image="_.get(data, item.image)"
                  @input="input"
                />

                <!-- Upload Multiple Image -->
                <multiple-image-field
                  v-else-if="item.type === 'multiple-image'"
                  v-model="item.value"
                  @input="input"
                />

                <!-- Other -->
                <b-input-group
                  v-else
                  :prepend="item.prepend"
                  :append="item.append"
                  class="input-group-merge"
                  :class="{ 'invalid': errors.length > 0 }"
                >
                  <b-form-input
                    v-model="item.value"
                    :type="item.type || 'text'"
                    :state="errors.length > 0 ? false:null"
                    @input="input"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
        </div>

        <!-- Submit button -->
        <div class="border-top pt-2">
          <b-form-row class="justify-content-end">
            <!-- Reset -->
            <b-col
              v-if="false"
              cols="auto"
            >
              <b-button
                variant="flat-dark"
                @click="reset"
              >
                Reset
              </b-button>
            </b-col>
            <!-- Cancel -->
            <b-col cols="auto">
              <b-button
                variant="flat-dark"
                @click="cancel"
              >
                Cancel
              </b-button>
            </b-col>
            <!-- Save and stay still, do not show on default form -->
            <b-col
              v-if="(create || update) && !returnOnly"
              cols="auto"
            >
              <b-button
                v-b-tooltip.v-dark.top="{ title: 'No changes', disabled: isSubmitAllowed }"
                :disabled="loadingSubmit || !isSubmitAllowed"
                type="submit"
                variant="primary"
                class="d-flex align-items-center"
                @click="submit('stay')"
              >
                <b-spinner
                  v-if="loadingSubmit && submitType === 'stay'"
                  class="mr-50"
                  small
                />
                <span v-if="update">Simpan & Lanjut Ubah</span>
                <span v-else>Simpan & Tambah Lainnya</span>
              </b-button>
            </b-col>
            <!-- Save and return, do not show on default form -->
            <b-col
              v-if="create || update"
              cols="auto"
            >
              <b-button
                v-b-tooltip.v-dark.top="{ title: 'No changes', disabled: isSubmitAllowed }"
                :disabled="loadingSubmit || !isSubmitAllowed"
                type="submit"
                variant="primary"
                class="d-flex align-items-center"
                @click="submit('return')"
              >
                <b-spinner
                  v-if="loadingSubmit && submitType === 'return'"
                  class="mr-50"
                  small
                />
                <span v-if="update">Simpan {{ label }}</span>
                <span v-else>Simpan {{ label }}</span>
              </b-button>
            </b-col>
            <!-- Default submit -->
            <b-col
              v-if="!create && !update"
              cols="auto"
            >
              <b-button
                :disabled="loadingSubmit || !isSubmitAllowed"
                type="submit"
                variant="primary"
                class="d-flex align-items-center"
                @click="submit('stay')"
              >
                <b-spinner
                  v-if="loadingSubmit"
                  class="mr-50"
                  small
                />
                <span v-if="submitLabel">{{ submitLabel }}</span>
                <span v-else>Simpan</span>
              </b-button>
            </b-col>
          </b-form-row>
        </div>
      </b-form>
    </validation-observer>
  </b-overlay>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import '@/assets/scss/components/quill.css'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, max } from '@validations'
import { updatedDiff } from 'deep-object-diff'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ImageField from '@/layouts/components/ImageField.vue'
import flatPickr from 'vue-flatpickr-component'
import VueSlider from 'vue-slider-component'
import { quillEditor } from 'vue-quill-editor'
import debounce from 'lodash/debounce'

import {
  BRow,
  BFormRow,
  BCol,
  BForm,
  BFormInput,
  BFormTextarea,
  BButton,
  BBadge,
  BFormCheckbox,
  BSpinner,
  BOverlay,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormRadioGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import MultipleImageField from '@/layouts/components/MultipleImageField1.vue'
import VariantEditor from '@/layouts/components/VariantEditor.vue'
import SubVariantEditor from '@/layouts/components/SubVariantEditor.vue'
import SkuEditor from '@/layouts/components/SkuEditor.vue'
import FormLabel from '@/layouts/components/FormLabel.vue'

export default {
  components: {
    BFormRow,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormTextarea,
    BButton,
    BBadge,
    BFormCheckbox,
    VueSlider,
    BSpinner,
    BOverlay,
    ImageField,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    quillEditor,
    flatPickr,
    BFormRadioGroup,
    ValidationProvider,
    ValidationObserver,
    MultipleImageField,
    VariantEditor,
    SubVariantEditor,
    SkuEditor,
    FormLabel,
    vSelect,
  },
  props: {
    data: {
      type: Object,
      default: () => {
      },
    },
    fields: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    create: {
      type: Boolean,
      default: false,
    },
    update: {
      type: Boolean,
      default: false,
    },
    loadingDetail: {
      type: Boolean,
      default: false,
    },
    submitLabel: {
      type: String,
      default: '',
    },
    loadingSubmit: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    successMessage: {
      type: String,
      default: '',
    },
    returnOnly: {
      type: Boolean,
      default: false,
    },
    // do not load data on initial state(mounted)
    lazy: {
      type: Boolean,
      default: false,
    },
    // submit the one that changed only
    diffOnly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      submitType: '',
      isSubmitAllowed: true,
      showFormData: process.env.VUE_APP_APP_DEBUG === 'true',

      defaultFields: this._.cloneDeep(this.fields),

      quillOptions: {
        modules: {
          // doc: https://quilljs.com/docs/modules/toolbar/
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ align: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link'],
            ['blockquote', 'code-block'],
            [{ indent: '-1' }, { indent: '+1' }],
          ],
        },
      },

      flatPickrConfig: {
        altInput: true,
        altFormat: 'j F Y, H:i',
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        time_24hr: true,
      },

      searchOptionsLimit: 5,

      // validation rules
      required,
      email,
      max,
    }
  },
  watch: {
    // this listener won't be triggered if the component is not visible(mounted)
    // when the data changes. For instance if the component within a modal
    // and the modal is not visible on the first place
    data() {
      this.bindFormData()
    },
    defaultFields(value) {
      if (value && value.find(item => item.key === 'skus')) {
        const lalala = value.find(item => item.key === 'skus')

        if (lalala && lalala.value) {
          // console.log('panjangnya', lalala.value.length)
        }
      }
    },
  },
  created() {
    if (this.update) {
      // reset previous form data (if any)
      this.reset()
    }
  },
  mounted() {
    // preload select options from API
    this.loadFieldOptions()

    if (!this.update) {
      return
    }

    // Only get data from API on update state and not lazy mode
    if (!this.lazy) {
      this.loadData(this.$route.params.id)
    } else if (this.data) {
      // Bind data to form if the data is already exist
      // Must be because of the component is visible when the data loaded already
      // For instance if the component within a modal
      this.bindFormData()
    }

    // should edit first to enable submit button
    this.isSubmitAllowed = false
  },
  methods: {
    loadData(id) {
      if (this.$listeners && this.$listeners.load) {
        this.$emit('load', id)
      }
    },
    submit(type) {
      return
      // eslint-disable-next-line no-unreachable
      console.group('data')
      // console.log(this.defaultFields)
      // console.log(this.data, this.formData())
      // console.log(this.formData())
      console.groupEnd()
      this.$refs.formValidation.validate().then(async success => {
        if (success) {
          this.submitType = type

          const data = this.update && this.diffOnly
            ? this.updatedData()
            : this.formData()

          // console.log('data', data)
          // return

          // if on update mode
          // eslint-disable-next-line no-unreachable
          if (this.update) {
            this.$emit('submit', this.$route.params.id, data, this.submitCallback)
            return
          }

          this.$emit('submit', data, this.submitCallback)
        }
      })
    },
    submitCallback() {
      let successMessage = this.successMessage || 'The data has been submitted'

      if (this.create || this.update) {
        successMessage = this.label
          ? `${this.label} berhasil disimpan.`
          : 'Berhasil disimpan'
      }

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Sukses',
          icon: 'CheckIcon',
          text: successMessage,
          variant: 'success',
        },
      })

      // TODO: need to optimize the reset function, apply form reset rather than emit reset
      if (!this.update && this.submitType === 'stay') {
        this.reset()

        // load all the options again after reset.
        // because in this point all the searchable options are empty
        this.loadFieldOptions()
      }

      if (this.update && this.submitType === 'stay') {
        this.isSubmitAllowed = false
      }

      if (this.submitType === 'return') {
        this.$router.back()
      }
    },
    getRules(field) {
      const rules = []

      if (field.required) {
        rules.push('required')
      }

      if (field.type === 'email') {
        rules.push('email')
      }

      if (field.minLength) {
        rules.push(`min:${field.minLength}`)
      }

      if (field.maxLength) {
        rules.push(`max:${field.maxLength}`)
      }

      if (field.min) {
        rules.push(`min_value:${field.min}`)
      }

      if (field.max) {
        rules.push(`max_value:${field.max}`)
      }

      return rules.join('|')
    },
    input() {
      const data = this.updatedData()

      this.isSubmitAllowed = !this._.isEmpty(data)
    },
    sanitizeObject(data) {
      const obj = data
      // console.log('data', data)
      Object.keys(obj).forEach(key => {
        if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
          delete obj[key]
        } else if (typeof obj[key] === 'object') {
          this.sanitizeObject(obj[key]) // Recursively sanitize nested objects
          if (Object.keys(obj[key]).length === 0) {
            delete obj[key] // If the nested object becomes empty, remove it
          }
        }
      })
      return obj
    },
    formData() {
      // convert field's array to object
      // there was a code to sanitize below result. I forgot why I put it.
      // so I remove because when I change a un-required field to empty, it is not detect as a change.
      // most like the usage is for the SkuEditor
      // TODO: check the SkuEditor usage for this function
      // console.log('formData', this.defaultFields)
      return this.defaultFields
        .map(item => ({
          ...item,
          value: item.value && item.type === 'number'
            ? parseInt(item.value, 10)
            : item.value,
        }))
        .filter(item => {
          const isEmpty = [undefined, null, ''].includes(item.value)
          const isSection = item.type === 'section'

          // (only get non-empty field on create mode) or not required(?)
          // because the !isRequired field is allowed to be empty
          const isIncluded = this.update
            ? item.value !== item.initial_value && (!isEmpty || !item.required)
            : !isEmpty || !item.required

          return isIncluded
            && !isSection
            && (!item.visibility || item.visibility.callback(this.defaultFields))
        })
        .reduce((o, { key, value }) => this._.set(o, key, value), {})
    },
    // returns only the values that have been changed
    // computed cannot listen to property's child changes
    updatedData() {
      // console.log('updatedData:', this.data, this.formData())
      return this.diff(this.data, this.formData())
    },
    // TODO: need to optimize the diff function
    diff(obj1, obj2) {
      // the updatedDiff function is always return 'array' changes as an 'object'
      // 'object' is make sense because if we have more than 1 item in array,
      // and the array index 0 doesn't have any changes but the array index 1
      // there is a changes, if the return is still in an array, then the array
      // index one will become index 0. Because we cannot have an array with index 1
      // without index 0.
      // No worries, PHP based API will read data[0].attribute and the
      // data.0.attribute as the same thing
      // TODO: empty nested object should get ignored
      const updated = updatedDiff(obj1, obj2)
      // console.group('Changes')
      // console.log('obj', obj1, obj2)
      // console.log('added', added)
      // console.log('before', updated)

      // obj2 defined means it is edit mode
      if (obj1) {
        // Object.keys(obj1)
        //   .filter(key => updated[key] !== undefined // is updated
        //     && Array.isArray(obj1[key]) // is an array
        //     && obj1[key].length // is not empty array
        //     && typeof obj1[key][0] === 'object') // is array of objects
        //   .forEach(key => {
        //     Object.keys(obj2[key])
        //       .forEach(arrayKey => {
        //         // all Object.keys code above doesn't have any effect to the data
        //         // except the code below
        //         updated[key].push(obj2[key][arrayKey])
        //       })
        //   })

        // console.log('obj2', obj2)

        // put the array object ids if there are any changes
        // for example when the sku's price changed, put the sku id
        // so, it will be easier for API to know which sku to update
        Object.keys(obj2)
          .filter(key => Array.isArray(obj2[key]) // is an array
            && obj2[key].length // is not empty array
            && typeof obj2[key][0] === 'object') // is array of objects
          .forEach(key => {
            // Updated
            const obj2UpdatedOnly = obj2[key].filter(item => item._is_updated)
            const obj2UpdatedOnlyIds = obj2UpdatedOnly.map(item => item.id)
            const obj1UpdatedOnly = obj1[key].filter(item => obj2UpdatedOnlyIds.includes(item.id))

            const updatedOnly = updatedDiff(obj1UpdatedOnly, obj2UpdatedOnly)
            const updatedOnlyDiff = []

            Object.keys(obj2UpdatedOnly)
              .forEach(arrayKey => {
                updatedOnlyDiff.push({
                  id: obj2UpdatedOnly[arrayKey].id,
                  ...updatedOnly[arrayKey],
                  _is_updated: true,
                })
              })

            // Deleted
            const obj2DeletedOnly = obj2[key].filter(item => item._is_deleted)
            const deletedOnlyDiff = obj2DeletedOnly.map(item => ({
              id: item.id,
              _is_deleted: true,
            }))

            const allDiff = [...obj2[key]]
              .filter(item => item._is_updated || item._is_added || item._is_deleted)

            Object.keys(allDiff)
              .forEach(arrayKey => {
                if (allDiff[arrayKey]._is_updated) {
                  allDiff[arrayKey] = updatedOnlyDiff.find(item => item.id === allDiff[arrayKey].id)
                } else if (allDiff[arrayKey]._is_deleted) {
                  allDiff[arrayKey] = deletedOnlyDiff.find(item => item.id === allDiff[arrayKey].id)
                }
              })

            if (allDiff.length > 0) {
              updated[key] = allDiff
            } else {
              delete updated[key]
            }
          })
      }
      if (updated.skus) {
        console.group('Skus')
        updated.skus.forEach((item, index) => console.log(index, item))
        console.groupEnd()
      } else {
        console.log('Empty')
      }
      // console.groupEnd()
      return updated
    },
    cancel() {
      if (this.$listeners && this.$listeners.cancel) {
        this.$emit('cancel')
        return
      }

      // default action on cancel
      this.$router.back()
    },
    getValue(item) {
      // set default if not yet being set
      if (typeof item.value === 'undefined' && this.update) {
        return this._.get(this.data, item.key)
      }

      return item.value
    },
    reset() {
      // reset to default fields without 'value'
      // console.log('lalala reset')
      this.defaultFields = this._.cloneDeep(this.fields)

      // reset validation state
      if (this.$refs.formValidation) {
        this.$refs.formValidation.reset()
      }
    },
    bindFormData() {
      // Set the form field's default value
      // using the data from API
      // console.log('lalala bindFormData')
      this.defaultFields = this.defaultFields.map(item => {
        const value = this.getValue(item)

        return ({
          ...item,
          value,
          initial_value: Array.isArray(value) ? [...value] : value,
        })
      })

      this.loadFieldSelected()
      // load initial selected for 'select' or 'multiple'
      // from the actionSearch with ids parameter. bind to
      // .selected and valueOptions
      this.loadFieldSelectedAction()

      this.loadFieldMultiple()
    },
    loadFieldOptions() {
      // fields that has 'select' or 'multiple' type
      const selectFields = this.defaultFields.filter(item => (item.initialOptions
          && (item.type === 'select' || item.type === 'multiple'))
        && item.actionSearch)

      selectFields.forEach(item => {
        this.$store.dispatch(item.actionSearch, {
          perPage: this.searchOptionsLimit,
        })
          .then(data => this.setFieldOptions(item, data))
      })
    },
    // multiple without action
    loadFieldMultiple() {
      // fields that has 'multiple' type but doesn't have action search. Basically a free text or has static options
      const multipleFields = this.defaultFields.filter(item => (item.type === 'multiple'
        && !item.actionSearch))
      multipleFields.forEach(item => {
        if (item.value) {
          this.setFieldMultiple(item, item.value)
        }
      })
    },
    setFieldMultiple(item, value) {
      if (!value || !value.length) {
        return
      }
      const fields = this._.cloneDeep(this.defaultFields)
      const index = fields.findIndex(field => field.key === item.key)
      // static options
      if (item.options) {
        const fallbackOptionKey = item.actionSearch ? 'id' : 'value'
        fields[index].valueOptions = value.map(optionValue => item.options
          .find(option => option[item.optionKey || fallbackOptionKey] === optionValue))
      } else {
        // free text without options
        fields[index].valueOptions = value.map(text => ({ text }))
      }
      this.defaultFields = fields
    },
    loadFieldSelectedAction() {
      // fields that has 'select' or 'multiple' type
      const selectFields = this.defaultFields.filter(item => (item.type === 'select'
          || item.type === 'multiple')
        && item.actionSearch)

      selectFields.forEach(item => {
        if (item.value) {
          const ids = item.type === 'select' ? item.value : item.value.join(',')
          this.$store.dispatch(item.actionSearch, {
            ids,
            perPage: item.type === 'select' ? 1 : item.value.length,
          })
            // the 'data' below is an array
            .then(data => this.setFieldSelected(item, data))
        }
      })
    },
    loadFieldSelected() {
      // fields that has 'select' and static options
      const selectFields = this.defaultFields.filter(item => item.type === 'select' && item.options)

      selectFields.forEach(item => {
        if (item.value) {
          this.setFieldSelect(item, item.value)
        }
      })
    },
    setFieldSelect(item, value) {
      if (!value) {
        return
      }
      const fields = this._.cloneDeep(this.defaultFields)
      const index = fields.findIndex(field => field.key === item.key)
      const fallbackOptionKey = 'value'
      fields[index].selected = item.options
        .find(option => option[item.optionKey || fallbackOptionKey] === value)
      this.defaultFields = fields
    },
    searchOptions: debounce(function search(field, keyword, setLoading) {
      // do not reload to initial options if options already exists
      // this behavior may not suitable if you want to always ...
      // load the default list when keyword is empty
      if (!field.initialOptions && field.options && field.options.length && !keyword) {
        return
      }
      setLoading(true)

      this.$store.dispatch(field.actionSearch, {
        keyword,
        perPage: this.searchOptionsLimit,
      })
        .then(data => this.setFieldOptions(field, data))
        .finally(() => {
          setLoading(false)
        })
    }, 1000),
    setFieldOptions(item, data) {
      const fields = this._.cloneDeep(this.defaultFields)
      const index = fields.findIndex(field => field.key === item.key)
      fields[index].options = data
      this.defaultFields = fields
    },
    setFieldSelected(item, data) {
      if (!data || !data.length) {
        return
      }

      const fields = this._.cloneDeep(this.defaultFields)
      const index = fields.findIndex(field => field.key === item.key)

      if (item.type === 'multiple') {
        // if it is a multiple select field, set all
        fields[index].valueOptions = data
      } else {
        // if it is a single select field, get the first index
        [fields[index].selected] = data
      }

      this.defaultFields = fields
    },
    inputDeselect(field, option) {
      // when clear value but not multiple value
      // because multiple value doesn't use vue-select default selected key
      if (option === null) {
        const fields = this._.cloneDeep(this.defaultFields)
        const index = fields.findIndex(item => item.key === field.key)

        if (fields[index].value && fields[index].type !== 'multiple') {
          fields[index].selected = null
          fields[index].value = null

          this.defaultFields = fields
          // TODO: should use this.input(), it doesn't work
          // this.input()
          this.isSubmitAllowed = true
        }
      }
    },
    inputSelect(field, option) {
      const fields = this._.cloneDeep(this.defaultFields)
      const index = fields.findIndex(item => item.key === field.key)
      const fallbackOptionKey = field.actionSearch ? 'id' : 'value'
      const value = option[field.optionKey || fallbackOptionKey]
      // if multiple select, clear the input after selecting
      if (field.type === 'multiple') {
        fields[index].selected = null
        // valueOptions is the array of selected options
        if (!fields[index].valueOptions) {
          fields[index].value = [value]
          fields[index].valueOptions = [option]
        } else if (fields[index].valueOptions
          .findIndex(valueOption => valueOption[field.optionKey
          || fallbackOptionKey] === option[field.optionKey
          || fallbackOptionKey]) === -1) {
          // push if not selected yet
          fields[index].value.push(value)
          fields[index].valueOptions.push(option)
        }
      } else {
        fields[index].value = value
      }
      this.defaultFields = fields
      // TODO: should use this.input()
      // this.input()
      this.isSubmitAllowed = true
    },
    inputBasicMultiple(field, e) {
      const fields = this._.cloneDeep(this.defaultFields)
      const index = fields.findIndex(item => item.key === field.key)
      const { value } = e.target

      if (!fields[index].value || !Array.isArray(fields[index].value)) {
        fields[index].value = [value]
        fields[index].valueOptions = [{ text: value }]

        this.defaultFields = fields
      } else if (!fields[index].value.includes(value)) {
        fields[index].value.push(value)
        fields[index].valueOptions.push({ text: value })

        this.defaultFields = fields
      }

      e.target.value = ''
    },
    removeOption(field, index) {
      field.valueOptions.splice(index, 1)
      field.value.splice(index, 1)

      const fields = this._.cloneDeep(this.defaultFields)
      const fieldIndex = fields.findIndex(item => item.key === field.key)
      fields[fieldIndex] = field

      this.defaultFields = fields
      // TODO: should use this.input()
      // this.input()
      this.isSubmitAllowed = true
    },
    onBlurSelect(field) {
      if (field.type !== 'multiple') {
        return
      }

      this.$refs[`select-input-${field.key}`][0].focus()
      this.$refs[`select-input-${field.key}`][0].blur()
    },
    changeSkus(value, field) {
      const fields = this._.cloneDeep(this.defaultFields)
      const fieldIndex = fields.findIndex(item => item.key === field.skus)
      fields[fieldIndex].value = value
      // console.log('lalala changeSkus', this.defaultFields)
      this.defaultFields = fields
      this.input()
    },
  },
}
</script>

<style lang="scss">
</style>
