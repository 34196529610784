<template>
  <b-form-row class="align-items-center">
    <!-- Label -->
    <b-col cols="auto">
      <h5 class="mb-0 font-weight-bolder">
        {{ label }}
      </h5>
    </b-col>
    <!-- Required badge -->
    <b-col>
      <b-badge
        v-if="required"
        variant="light-secondary"
      >
        Wajib
      </b-badge>
    </b-col>
  </b-form-row>
</template>

<script>
import {
  BFormRow,
  BBadge,
  BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BFormRow,
    BBadge,
    BCol,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>

</style>
