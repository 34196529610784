<template>
  <div>
    <!-- section -->
    <div class="mb-2 mt-3">
      <hr class="mb-2">
      <b-row>
        <b-col
          md="8"
          xl="6"
        >
          <h3>Varian</h3>
          <p>Tambah varian agar pembeli dapat memilih produk yang sesuai.</p>
        </b-col>
        <b-col
          v-if="!hasVariant"
          md="4"
          xl="3"
          class="text-md-right"
        >
          <b-button
            variant="outline-primary"
            @click="enableVariant"
          ><feather-icon
            icon="PlusIcon"
            class="mr-50"
          />Tambah Varian</b-button>
        </b-col>
        <b-col
          v-else
          md="4"
          xl="3"
          class="text-md-right"
        >
          <b-button
            variant="outline-secondary"
            @click="deleteVariantSubVariant"
          ><feather-icon
            icon="TrashIcon"
            class="mr-50"
          />Hapus Varian</b-button>
        </b-col>
      </b-row>
    </div>

    <div class="mt-1">
      <b>variant</b>: {{ !value ? 'empty' : value }}, <b>skus</b>: {{ skus }}
    </div>

    <!-- variant label -->
    <FormLabel
      v-if="hasVariant"
      label="Tipe Varian 1"
    />
    <b-row
      v-if="hasVariant"
      class="mt-50"
    >
      <!-- variant select -->
      <b-col
        md="4"
        xl="3"
      >
        <b-dropdown
          variant="outline-dark"
          block
          toggle-class="pl-1 border-default d-flex justify-content-between"
        >
          <!-- Content -->
          <template #button-content>
            <span
              v-if="variantLabel"
              class="text-capitalize"
            >{{ variantLabel }}</span>
            <span
              v-else
              class="text-muted"
            >Pilih..</span>
          </template>

          <b-dropdown-item
            v-for="(item, index) in options"
            :key="`variant-option-${index}`"
            :disabled="item === subVariantLabel"
            class="text-capitalize"
            @click="changeVariantLabel(item)"
          >{{ item }}</b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item>
            <div class="d-flex align-items-center w-100">
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              Tambah baru
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
      <!-- variant input -->
      <b-col
        v-if="variantLabel"
        md="8"
        xl="6"
      >
        <b-input-group class="input-group-merge position-relative">
          <b-form-input
            v-model="variantInput"
            :placeholder="`Ketik untuk menambahkan ${variantLabel || 'Variant'}`"
            @keypress.enter.prevent="addVariant"
          />
          <b-input-group-append
            class="cursor-pointer"
            is-text
            @click="addVariant"
          >
            <small class="mr-25">Enter</small><feather-icon icon="CornerDownLeftIcon" />
          </b-input-group-append>
        </b-input-group>

        <div
          v-if="variants.length"
          class="d-flex gap-1 flexwrap-wrap mt-50"
        >
          <b-badge
            v-for="(variant, index) in variants"
            :key="`variant-${index}`"
            class="whitespace-nowrap s-14 d-flex align-items-center"
            variant="primary"
          >
            {{ variant.name }}
            <feather-icon
              class="cursor-pointer ml-25"
              icon="XIcon"
              @click="removeVariant(index)"
            />
          </b-badge>
        </div>
      </b-col>
    </b-row>

    <div
      v-if="hasVariant"
      class="py-50"
    >
      <hr class="mb-0 mt-2">
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BBadge,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from 'bootstrap-vue'
import FormLabel from '@/layouts/components/FormLabel.vue'
// import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BBadge,
    BFormInput,
    FormLabel,
    BInputGroup,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    // ValidationProvider,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    subVariant: {
      type: Object,
      default: () => {},
    },
    skus: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      variantInput: '',
      subVariantInput: '',
      hasVariant: false,
      // hasSubVariant: false,
      // variantLabel: null,
      // subVariantLabel: null,
      // variants: [],
      // subVariants: [],
      // skus: [],
      options: ['ukuran', 'warna', 'laminasi'],
    }
  },
  computed: {
    variants() {
      if (!this.value) {
        return []
      }

      return this.value.options
    },
    variantLabel() {
      if (!this.value) {
        return null
      }

      return this.value.name
    },
    hasSubVariant() {
      return this.subVariant
    },
    subVariants() {
      if (!this.subVariant) {
        return []
      }

      return this.subVariant.options
    },
    subVariantLabel() {
      if (!this.subVariant) {
        return null
      }

      return this.subVariant.name
    },
  },
  watch: {
    value(value, oldValue) {
      // ignore if not first value bind. value bind is only on update mode
      if (oldValue) {
        return
      }

      if (this.variants.length) {
        this.hasVariant = true
      }

      // if variant label from database is not in the default option list
      if (this.variantLabel && this.options.indexOf(this.variantLabel) === -1) {
        // put in the list
        this.options.push(this.variantLabel)
      }
    },
  },
  methods: {
    changeVariantLabel(value) {
      this.change({
        ...this.value,
        name: value,
      })
    },
    changeOptions(value) {
      this.change({
        ...this.value,
        options: value,
      })
    },
    change(value) {
      this.$emit('input', value)
    },
    addVariant() {
      if (!this.variantInput) {
        return
      }

      // do not push if already exists
      if (this.variants.findIndex(item => item.name === this.variantInput) !== -1) {
        this.variantInput = ''
        return
      }

      // push to variants with id null, means this variant is not in database yet
      this.variants.push({ id: null, name: this.variantInput })

      this.addSku(this.variantInput, this.variants.length - 1)

      this.variantInput = ''
    },
    addSku(variantName, variantIndex) {
      const skus = [...this.skus]
      const sku = {
        price: null,
        compare_price: null,
        weight: null,
        stock: null,
        sku_number: null,
        min_purchase: 1,
        max_purchase: null,
        amount_multiple: 1,
        variant: {
          name: variantName,
          index: variantIndex,
        },
        _is_added: true,
      }

      if (this.hasSubVariant) {
        // loop all the sub-variants to pair with current variant
        this.subVariants.forEach((item, index) => {
          skus.push({
            ...sku,
            sub_variant: {
              name: item.name,
              index,
            },
          })
        })
      } else {
        // if do not have sub variant, then just push it
        skus.push(sku)
      }

      this.$emit('skus', skus)
    },
    removeVariant(index) {
      const variant = this.variants[index]
      this.variants.splice(index, 1)

      const skus = this.skus.map(item => {
        if (item.variant && item.variant.name === variant.name) {
          return {
            id: item.id,
            _is_deleted: true,
          }
        }

        return item
      })

      this.$emit('skus', skus)
    },

    addSubVariant() {
      if (!this.subVariantInput) {
        return
      }

      // do not push if already exists
      if (this.subVariants.findIndex(item => item.name === this.subVariantInput) !== -1) {
        this.subVariantInput = ''
        return
      }

      // push to sub-variants with id null, means this sub-variant is not in database yet
      this.subVariants.push({ id: null, name: this.subVariantInput })

      // loop all the variants to pair with current sub-variant
      this.variants.forEach((item, index) => {
        this.addSku(item.name, index, this.subVariantInput, this.subVariants.length - 1)
      })

      this.subVariantInput = ''
    },
    removeSubVariant(index) {
      this.subVariants.splice(index, 1)
    },
    deleteVariantSubVariant() {
      this.hasVariant = false
      this.change(null)

      // this.hasVariant = false
      // this.hasSubVariant = false
      //
      // this.variantLabel = null
      // this.subVariantLabel = null
      //
      // this.variants = []
      // this.subVariants = []
      //
      // this.variantInput = ''
      // this.subVariantInput = ''
      //
      // this.skus = []
    },
    enableVariant() {
      this.hasVariant = true
      this.change({
        name: null,
        options: [],
      })
    },
    enableSubVariant() {
      this.hasSubVariant = true
    },
  },
}
</script>

<style scoped>

</style>
