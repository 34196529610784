<template>
  <div>
    <b-form-row class="align-items-center">
      <b-col cols="auto">
        <!-- Image preview -->
        <div class="w-100px h-100px border-2 border-dashed border-default rounded-lg">
          <a
            v-if="imageTemp"
            :href="imageTemp"
            target="_blank"
            class="w-100 h-100 d-block p-25"
          >
            <b-overlay
              class="h-100 w-100"
              :show="loading"
            >
              <b-img
                :src="imageTemp | thumbnail(89)"
                class="w-100 h-100 img-contain rounded"
              />
            </b-overlay>
          </a>
          <div
            v-else
            class="d-flex align-items-center justify-content-center cursor-pointer w-100 h-100"
            @click="selectFile"
          >
            <div class="text-center">
              <feather-icon
                icon="UploadIcon"
                size="16"
                class="icon"
              />
              <div class="s-14 mt-25">
                Upload
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <!-- Reset file and preview image -->
      <b-col
        v-if="imageTemp"
        cols="auto"
      >
        <b-button
          variant="secondary"
          class="pl-1"
          @click="removeFile"
        >
          <feather-icon
            icon="XIcon"
            class="mr-50"
          />
          Hapus
        </b-button>
      </b-col>
      <!-- Select or change image button -->
      <b-col cols="auto">
        <b-button
          variant="primary"
          @click="selectFile"
        >
          <span v-if="imageTemp">Ubah Gambar</span>
          <span v-else>Pilih Gambar</span>
        </b-button>
      </b-col>
    </b-form-row>
    <!-- Actual input field -->
    <b-form-file
      ref="file"
      v-model="file"
      class="d-none"
      @input="change"
    />
    <!-- Image url input field -->
    <!-- This is the value that will be submitted to the API -->
    <input
      v-model="defaultValue"
      hidden
    >
  </div>
</template>

<script>
import {
  BFormRow,
  BCol,
  BButton,
  BFormFile,
  BImg,
  BOverlay,
} from 'bootstrap-vue'

export default {
  components: {
    BFormRow,
    BCol,
    BButton,
    BFormFile,
    BImg,
    BOverlay,
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      file: null,
      imageTemp: this.image,
      loading: false,
    }
  },
  computed: {
    defaultValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    image(value) {
      this.imageTemp = value
    },
    value(value) {
      if (!value) {
        this.file = null
        this.imageTemp = null
      }
    },
  },
  methods: {
    // trigger click the actual input file field
    selectFile() {
      this.$refs.file.$el.childNodes[0].click()
    },
    // set Image in the next tick after the image selected,
    // after this.value has been refreshed
    change() {
      if (!this.file) {
        return
      }

      this.imageTemp = URL.createObjectURL(this.file)

      this.loading = true
      this.$store.dispatch('image/upload', this.file)
        .then(res => {
          const { id, image_url: imageUrl } = res.data.data
          this.defaultValue = id
          this.imageTemp = imageUrl
        })
        .catch(() => {
          this.file = null
        })
        .finally(() => {
          this.loading = false
        })
    },
    removeFile() {
      this.defaultValue = null
    },
  },
}
</script>

<style scoped>

</style>
